import React from 'react'
import classNames from 'classnames'

import { requestForAutoOpenKulinariiFaster } from '../../apiRequests/callToAction'
import DefaultLayout from '../../layout/default'
import AutomationMenu from '../../components/_V2/AutomationMenu'
import SimpleFeatures from '../../components/_V2/SimpleFeatures'

import ProductPresentation from '../../pagesSections/avtomatizaciya-kulinarii/ProductPresentation'
import Presentation
	from '../../pagesSections/avtomatizaciya-kulinarii/ProductPresentation/assets/cooking-presentation.png'
import CtaForm from '../../pagesSections/index/CtaForm'
import CookingCtaForm from '../../pagesSections/avtomatizaciya-kulinarii/CookingCtaForm'
import CalculationFeatures from '../../pagesSections/avtomatizaciya-kulinarii/CalculationFeatures'
import ModesFeatures from '../../pagesSections/avtomatizaciya-kulinarii/ModesFeatures'
import InterfaceFeatures from '../../pagesSections/avtomatizaciya-kulinarii/InterfaceFeatures'
import AccountingFeatures from '../../pagesSections/avtomatizaciya-kulinarii/AccountingFeatures'

import featuresData from '../../pages-data/_V2/automatization/cooking/features'
import availableFeatures from '../../pages-data/_V2/automatization/cooking/available-features'

import pageStyles from '../../styles/_V2/pagesStyles/styles.module.scss'
import styles from '../styles/_V2/automation.module.scss'
import AvailableFeatures from '../../pagesSections/automation/AvailableFeatures'
import AccountingFeaturesIntegrations from "../../pagesSections/intergations/AccountingFeatures"
import ProductsFeatures from "../../pagesSections/intergations/ProductsFeatures"
import AdditionalServices from "../../pagesSections/index/AdditionalServices"

export default function PageAutomatization() {
  const metaTags = [
    // {
    //   name: 'keywords',
    //   content: 'quick resto, автоматизация кафе, ресторанный бизнес, программа учёта, склад, общепит',
    // },
    {
      name: 'description',
      content: 'Комплексная система автоматизации кулинарии ✔ Онлайн-касса ✔ Аналитика продаж и складской учет в облаке ✔ Единая система обслуживания для Зала и Доставки → Внедрение под ключ',
    },
    {
      property: 'og:url',
      content: 'https://quickresto.ru/automation/avtomatizaciya-kulinarii/',
    },
    {
      property: 'og:title',
      content: 'Автоматизация кулинарии — комплексная программа учета | Quick Resto',
    },
    {
      property: 'og:description',
      content: 'Комплексная система автоматизации кулинарии ✔ Онлайн-касса ✔ Аналитика продаж и складской учет в облаке ✔ Единая система обслуживания для Зала и Доставки → Внедрение под ключ',
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      property: 'og:image',
      content: `https://quickresto.ru${Presentation}`,
    },
  ]

  return (
    <DefaultLayout
      title="Автоматизация кулинарии — комплексная программа учета | Quick Resto"
      metaTags={metaTags}
      mainWrapperClassName={classNames(
			  pageStyles.pageWrapper,
			  pageStyles.pageGray,
			  pageStyles.automationPage,
      )}
      linkCanonical="https://quickresto.ru/automation/avtomatizaciya-kulinarii/"
      footerClassName={styles.automation__footer}
      schemaType="Product"
      schemaName={metaTags.find((tag) => tag.property === 'og:title').content}
      schemaDescription={metaTags.find((tag) => tag.property === 'og:description').content}
      schemaImage={metaTags.find((tag) => tag.property === 'og:image').content}
      schemaUrl={metaTags.find((tag) => tag.property === 'og:url').content}
    >
      <div
        className={classNames(
					  pageStyles.pageSection,
					  styles.automation__headline,
        )}
      >
        <ProductPresentation
          leftColumn={styles.automation__presentationLeftColumn}
          title={styles.automation__presentationTitle}
        />

        <AutomationMenu className={styles.automation__menu} />
      </div>

      <SimpleFeatures
        data={featuresData.filter((feature, index) => index === 0)}
        className={pageStyles.pageSection}
      />

      <CalculationFeatures className={pageStyles.pageSection} />

      <ModesFeatures className={pageStyles.pageSection} />

      <AvailableFeatures
        features={availableFeatures.features}
        className={pageStyles.pageSection}
      />

      <SimpleFeatures
        data={featuresData.filter((feature, index) => index === 1)}
        className={pageStyles.pageSection}
      />

      <InterfaceFeatures className={pageStyles.pageSection} />

      <CookingCtaForm
        className={pageStyles.pageSection}
        request={requestForAutoOpenKulinariiFaster}
      />

      <AccountingFeatures className={pageStyles.pageSection} />

      <SimpleFeatures
        data={featuresData.filter((feature, index) => index < 4)}
        className={pageStyles.pageSection}
      />

      <AccountingFeaturesIntegrations className={pageStyles.pageSection} theme="white" />

      <ProductsFeatures className={pageStyles.pageSection} theme="white" />

      <AdditionalServices className={styles.additionalServices} theme="white" />

      <SimpleFeatures
        data={featuresData.filter((_, index) => index >= 4)}
        className={pageStyles.pageSection}
      />

      <CtaForm
        isFry={false}
        isWhiteBackground
        className={pageStyles.pageSection}
      />
    </DefaultLayout>
  )
}
