import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import mediaContent from "./mediaContent.module.scss"
import { pagesLinks } from "../../../pages-data/_V2/common/links"

export default {
	features: [
		{
			desktopContentWidth: 490,
			tabName: "На развес",
			desktopTitle: "Весовой товар и продажа частями",
			desktopContent: (
				<p className={mediaContent.smallDescription}>
					Продажа готовых блюд, так похожих на домашнюю кухню — отличительная
					черта кулинарий и&nbsp;пекарен. Здесь зачастую отсутствует магазинная
					фасовка по порциям. Для того чтобы иметь возможность быстро
					обслуживать гостей в таком формате, Quick Resto автоматически
					рассчитывает стоимость в зависимости от веса или может поделить целое
					блюдо на равные части.
					<br /> Проведение подобных расчетов вручную требует времени и грозит
					неточностями, поэтому системы учета с электронными весами так
					популярны в&nbsp;кондитерских и кулинариях.
				</p>
			),
			detailLink: pagesLinks.terminal,
			mediaContent: (
				<StaticImage
					className={mediaContent.image1}
					src="./assets/cooking-calculation-1.png"
					alt="продажа блюд частями"
					breakpoints={[433, 740, 920, 636]}
					sizes="(max-width: 500px) 433px, (max-width: 800px) 740px, (max-width: 1319px) 920px, (min-width: 1320px) 636px"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
		{
			desktopContentWidth: 490,
			tabName: "Техкарты",
			desktopTitle: "Техкарты и модификаторы",
			desktopContent: (
				<p className={mediaContent.smallDescription}>
					Для корректного складского учета в кулинарии в Quick Resto
					используются технологические карты. Этот документ является основанием
					для расчета расхода продуктов и фактически определяет затраты
					предприятия на производство. Модификаторы позволяют менять свойства
					блюд, с их помощью можно «уточнять» способ приготовления или подачи
					блюда или продавать дополнительные компоненты к блюду.
					<br />
					Возможность использования модификаторов является неотъемлемой частью
					современной системы автоматизации кулинарии. В программе Quick Resto
					можно работать с полуфабрикатами и модификаторами, учитывая различные
					сценарии работы с блюдами и продуктами.
				</p>
			),
			detailLink: pagesLinks.menu,
			mediaContent: (
				<StaticImage
					className={mediaContent.image2}
					src="./assets/cooking-calculation-2.png"
					alt="программа для кулинарии"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
		{
			desktopContentWidth: 490,
			tabName: "Себестоимость",
			desktopTitle: "Себестоимость и калькуляция",
			desktopContent: (
				<p className={mediaContent.smallDescription}>
					Каждая кулинария имеет множество различных блюд, в которые входят
					разные ингредиенты, различные типы обработки, потери. Точные данные и
					оперативный учет позволяют оптимизировать производственные процессы
					и&nbsp;обеспечить стабильно высокую прибыль предприятия. Именно
					поэтому калькуляция себестоимости является одной из главных задач
					финансового и управленческого учета кулинарии. Для корректного учета
					расхода продуктов, объема потерь и себестоимости блюд поможет система
					автоматизации Quick Resto.
				</p>
			),
			detailLink: pagesLinks.menu,
			mediaContent: (
				<StaticImage
					className={mediaContent.image2}
					src="./assets/cooking-calculation-3.png"
					alt="расчет себестоимости блюд"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
	],
}
